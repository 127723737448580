.container--donation-teaser {
  width: var(--w-content);

  @media (--media-sm-only) {
    width: auto;
  }
}

.donation-teaser {
  padding: var(--spacing);
  display: flex;
  align-items: flex-end;
  gap: 4rem;

  @media (--media-sm-only) {
    flex-flow: wrap;
  }
}

.button--donation-teaser {
  flex-shrink: 0;
}

.donation-teaser__title {
  text-align: left;
  margin-bottom: var(--p);
}
