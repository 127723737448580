body:not(.is-pro) .film-list {
  max-width: var(--w-content);
  margin-inline: auto;

  .film-grid {
    grid-template-columns: 1fr 1fr;
  }
}

.is-pro {
  .film-list__container {
    overflow: auto;

    @media (--media-sm-only) {
      max-width: 100%;

      scrollbar-color: var(--dark) transparent;
      scrollbar-width: auto;
      -webkit-overflow-scrolling: auto;

      &::-webkit-scrollbar {
        width: 8px;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 8px;
        background: var(--dark);
        box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.5);
      }
      &::-webkit-scrollbar-thumb:window-inactive {
        background: var(--black-300);
      }
    }
  }

  .film-list {
    min-width: 74rem;
  }
}

.film-grid {
  --w: var(--w-content);
  --grid-cell: var(--c4);

  @media (--media-sm-only) {
    --grid-cell: var(--c2);
  }

  .film-box {
    display: grid;
    grid-template-areas: "image info";
    grid-template-columns: minmax(0, 0.56fr) minmax(0, 0.44fr);

    @media (--media-sm-only) {
      display: flex;
      flex-direction: column;
    }
  }
}

.film-grid--vertical {
  --w: var(--c-outer);
  --grid-cell: var(--c3);

  @media (--media-sm-only) {
    --grid-cell: var(--c2);
  }

  .film-box {
    display: flex;
    flex-direction: column;
  }
}

.film-box {
  position: relative;

  .film-tag {
    position: absolute;
    top: 0;
    left: 0;

    background-color: var(--feature-bg);
    color: var(--feature-fg);

    padding: 0.75rem 1rem;
    border-radius: 0 0 1rem 0;

    font-size: 1rem;
    font-weight: bold;
    line-height: 1rem;

    letter-spacing: 0.02rem;
  }
}

h3.press-voices-heading {
  max-width: var(--w-prose);
}

.press-voices {
  columns: 1;
  gap: 1.5rem;
  max-width: var(--w-prose);
  margin: auto;
}

@media (--media-md) {
  .press-voices {
    columns: 2;
  }
}

.press-voice {
  background-color: var(--white);
  border-radius: 0.375rem;
  display: inline-block;
  padding: 1.5rem;
  margin-bottom: 1.5rem;
  width: 100%;
  strong {
    display: block;
    margin-top: 1rem;
  }
  p {
    margin-bottom: 0;
  }
}

.button--pro-selection {
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.film-grid {
  .info__selection {
    position: absolute;
    height: 7rem;
    padding-block: 1rem;

    @media (--media-sm-only) {
      height: 5rem;
    }
  }

  .info__country.info__year {
    padding-bottom: 1rem;
  }
}

.button-wrap.film-teaser-container {
  margin-bottom: var(--margin-m);
}
