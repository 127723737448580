/* article specific ovrrides of standard elements */
.magazine,
.magazine-teaser {
  --grid-cell: var(--c5);
  color: var(--magazine-fg);

  .button--arrow-back {
    --button-fg: var(--gray);
  }

  --hero-font-color: var(--lime);
  --hero-details-bg: var(--dark-alpha40);

  --font-size-category: 1.25rem;

  main {
    background-color: var(--magazine-bg);
  }

  .search-hint {
    margin-left: calc(-1 * (1.5rem + 1rem));
  }
}

.container--magazine-logo {
  margin-left: 0;
  margin-right: 0;
  max-width: unset;
  background: var(--magazine-fg);
  color: red;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: var(--p) 0 calc(0.78 * var(--p));
}

.logo-image--magazine {
  max-height: 100%;
  height: 3.5rem;

  @media (--media-md) {
    height: 6rem;
  }
}

.container--articles {
  max-width: var(--w-content);

  @media (--media-sm-only) {
    width: auto;
  }
}

.category {
  text-decoration: none;
}

.form--magazine {
  margin-top: var(--margin-m);
}

.fields--magazine {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: var(--filter-size);
}

.field.field--magazine {
  .button--checkmark.button--checkmark-magazine {
    --category-border-radius: 9999px;
    --category-bg: var(--magazine-bg);
    --category-fg: var(--feature-bg);
    --category-border-color: var(--feature-bg);
  }

  @media (hover: hover) {
    .button--checkmark.button--checkmark-magazine:hover {
      background: var(--feature-bg);
    }
  }

  input:checked + .button--checkmark.button--checkmark-magazine {
    color: var(--category-bg);
    border-color: var(--category-bg);
    background: var(--feature-bg);
  }
}

.article-list {
  margin-top: 4rem;
  gap: var(--grid-gap--magazine);
  grid-template-columns: 1fr 1fr;

  margin-bottom: 10.75rem;

  @media (--media-sm-only) {
    grid-template-columns: 1fr;
  }
}

.article-box {
  display: flex;
  flex-direction: column;
  gap: var(--magazine-box-gap);
}

.article-box__image {
  margin-bottom: var(--spacing-s);

  img {
    max-width: unset;
    width: 100%;
  }
}

.article-box__details {
  display: flex;
  flex-direction: column;
  gap: var(--magazine-box-gap);
}

.article-box__categories {
  font-size: var(--font-size-category);
  display: flex;
  gap: 1rem;
}

.arcticle__backlink {
  margin-bottom: 1rem;
}

.article-box__link {
  margin-top: var(--magazine-box-gap);
  display: flex;
  justify-content: left;

  a,
  a:hover {
    color: inherit;
  }
}

.article__meta {
  display: flex;
  justify-content: space-between;
  margin: var(--p) 0;
}

.article__meta__info {
  display: flex;
  flex-flow: column;
}

.article__meta__some-links {
  display: flex;
  justify-content: right;
  gap: var(--spacing-s);
}

.article-teaser {
  position: relative;

  background-color: var(--article-teaser-bg);
  color: var(--article-teaser-fg);

  figcaption,
  h3,
  small {
    color: inherit;
  }

  padding: var(--spacing) 0;

  > .container {
    display: flex;
    align-items: center;
    gap: var(--spacing);

    flex-wrap: wrap;
    @media (--media-lg) {
      flex-wrap: nowrap;
    }
  }
}

.article-teaser__details,
.article-teaser__details__info {
  display: flex;
  flex-direction: column;
  gap: var(--grid-gap);
}

.article-teaser__details__link {
  display: flex;
  justify-content: right;
}

.article-teaser__image {
  flex-basis: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.article-box--featured {
  grid-column-start: 1;
  grid-column-end: 3;
  grid-row-start: 1;

  margin: 0 var(--c);

  & .article-box__image {
    margin-bottom: var(--spacing);
  }

  @media (--media-sm-only) {
    grid-column-end: 1;
  }
}

.article-box__categories--detail {
  justify-content: center;
}

.container--articles .pagination--load-more,
.button--magazine-teaser {
  --button-fg: var(--green);
  --button-bg: var(--black);
}

.article__backlink {
  margin-top: var(--spacing);
}

.article-box__author {
  text-align: center;
}

.article-titlebox {
  margin-bottom: 3.5rem;
}

.magazine--detail {
  .button--download {
    --button-bg: var(--black);
  }

  .hr-top {
    border-top: 1px solid var(--white);
  }

  .director-box {
    margin-top: var(--gap);
  }

  p.article__date {
    /* font-family: GT-Walsheim, Helvetica, Arial, sans-serif; */
    font-style: italic;
    font-size: 1.125rem;
    line-height: 2.2;
  }
}

.director-box__info {
  .button {
    --button-fg: var(--white);
    &:hover {
      color: var(--lime);
    }
  }
}

.magazine-paragraph {
  display: flex;
  flex-flow: wrap;
  justify-content: flex-start;
  max-width: var(--w-prose);
  margin-inline: auto;

  & .button {
    margin-inline: unset;
  }
}

.plugin--wrap.magazine-teaser {
  color: var(--magazine-fg);
  background-color: var(--magazine-bg);

  h2 {
    color: var(--magazine-fg);
  }

  .article-list {
    margin-bottom: 7.75rem;
  }
}

.magazine-teaser__title {
  margin-bottom: 4.5rem;
}

.magazine-teaser .article-list {
  margin-bottom: var(--featured-margin-bottom);
}

/* very ugly but would have to change a lot or use important */
.magazine-teaser__description p:not(.lead) {
  font-family: GT-Walsheim, Helvetica, Arial, sans-serif;
  font-size: 1.75rem;
  line-height: 1.42;
  width: var(--w-content);
  margin-inline: auto;
}

.magazine--similar-articles {
  --grid-cell: var(--c3);
}
