.streaming-teaser {
  padding: var(--margin) 0;
}

.streaming-teaser__link {
  --icon-fg: var(--yellow);

  font-size: 3.5rem;
  line-height: 1.2;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: var(--gap);

  @media (--media-sm-only) {
    font-size: 2.5rem;
  }
}
