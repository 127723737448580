.form.filter--form {
  --filter-count: 9;

  scroll-margin: var(--margin-m);
  margin-bottom: var(--featured-margin-bottom);

  .is-pro & {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
}

.fields {
  --btn-sort-size: 1.5rem;

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  margin: 0 auto;

  @media (--media-sm-only) {
    flex-flow: wrap;
  }
}

.guest-fields {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;

  @media (--media-sm-only) {
    flex-flow: wrap;
  }
}

body:not(.is-pro) {
  .field--filter {
    @media (--media-sm-only) {
      max-width: calc(50% - 0.5rem);
    }
  }
}

.field.field--filter {
  flex: 0 1 auto;
  position: relative;
  margin: 0px;

  /* let's get hacky */
  :not(.is-pro) {
    & > div {
      display: flex;

      & > * {
        flex: 0 1 content;
      }
    }
  }

  label {
    font-size: 0.875rem;
    font-weight: normal;
    line-height: 0.75em;
    position: absolute;
    top: 50%;
    left: 0.875rem;
    transform: translateY(-50%);
    pointer-events: none;
    color: var(--black-400);

    &.filled {
      opacity: 0;
    }
  }

  input {
    width: 100%;
    max-width: 100%;
    border: 1px solid var(--dark);
    padding: 0.25rem 0.25rem 0.25rem 0.875rem;
    padding-right: 2.5rem;
    color: var(--dark);
    font-size: 0.875rem;
    font-weight: normal;
    line-height: 1.4rem;
    border-radius: 2rem;
    transition:
      background-color 300ms ease,
      border 300ms ease,
      color 300ms ease;

    &::-webkit-calendar-picker-indicator {
      display: none !important;
    }

    &.has-content {
      background-color: var(--lime);
    }

    &:focus {
      box-shadow: 0px 0px 12px 0px rgba(10, 17, 41, 0.3);
      outline: none;
    }
  }

  .button-close {
    position: absolute;
    top: 50%;
    right: 0.875rem;
    transform: translateY(-50%);
    background: url(/static/assets/icon_close.svg) center center no-repeat;
    background-size: contain;
    height: 1.5rem;
    width: 1.5rem;
    -webkit-appearance: none;
    -moz-appearance: none;
    border: none;
    padding: 0px;
  }

  &.field--magazine {
    label {
      color: var(--gray);
    }

    input {
      color: var(--gray);
      border-color: var(--white);
      background-color: transparent;

      &:focus {
        color: var(--white);
        outline: none;
      }

      &.has-content {
        background-color: var(--lime);
        color: var(--dark);
        border-color: var(--black);
      }
    }
  }
}

.field--sorting {
  padding-right: calc(0.2rem + var(--btn-sort-size));

  /* not sure if this is present in other palces, so keep it inside the sorting field */
  .button-sort {
    position: absolute;
    right: 0;
    top: calc(50% - (var(--btn-sort-size) / 2));
  }

  &.field.field--filter .button-close {
    right: calc(0.875rem + 0.2rem + var(--btn-sort-size));
  }
}

.field.field--button {
  margin: 0;

  label.checkmark {
    margin: 0;
  }

  .button--checkmark {
    --category-bg: var(--dark);
    --category-fg: var(--gray);
    --category-border-radius: 0.25rem;
    --category-border-color: var(--dark);

    transition:
      background-color 300ms ease,
      border 300ms ease,
      color 300ms ease;
  }

  &.field--classic .button--checkmark {
    --category-bg: var(--red);
    --category-fg: var(--dark);
    --category-border-color: var(--red);
  }
}

label.checkmark {
  margin: 0;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  input[type="checkbox"] {
    position: absolute;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    height: 0px;
    width: 0px;
  }

  input:checked ~ .button--checkmark {
    color: var(--category-bg);
    background: var(--lime);
    border-color: var(--category-bg);
    --icon-fg: var(--category-bg);

    .field--classic & {
      background: var(--green);
      border-color: var(--category-fg);
      --icon-fg: var(--category-fg);
      color: var(--category-fg);
    }
    svg {
      display: block;
    }
  }
}

.is-pro .fields {
  display: grid;
  --grid-gap: 1rem;
  gap: var(--grid-gap);
  grid-template-columns:
    var(--pro-grid-col-0)
    var(--pro-grid-col-1)
    var(--pro-grid-col-2)
    var(--pro-grid-col-3)
    var(--pro-grid-col-4)
    var(--pro-grid-col-5)
    var(--pro-grid-col-6)
    var(--pro-grid-col-7)
    var(--pro-grid-col-8);
}

.search-hint {
  background: url(/static/assets/icon_search.svg) center center no-repeat;
  background-size: contain;
  width: 1.5rem;
  height: 1.5rem;

  .form--magazine & {
    background: url(/static/assets/icon_search-light.svg) center center
      no-repeat;
    background-size: contain;
  }

  @media (--media-sm-only) {
    width: 100%;
  }
}

.select-all__button {
  --button-fg: var(--dark);
  padding-inline: 0;
}

.button-sort {
  background: url(/static/assets/icon_sort.svg) center center no-repeat;
  background-size: contain;
  width: var(--btn-sort-size);
  height: var(--btn-sort-size);
  cursor: pointer;
  border: none;
  padding: 0px;
}

.film-list-detail {
  --link-fg: var(--dark);

  border-radius: 0.375rem;
  background: var(--white);
  box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.1);
  padding: 2rem;
  margin-bottom: var(--featured-margin-bottom);

  h3 {
    margin-bottom: 1rem;
  }
}

.film-list-detail__header {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 1rem;
}

.film-list-detail__body {
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.4em;

  p:last-child {
    margin-bottom: 1rem;
  }
}

.film-list-detail__filmography {
  margin-top: 1rem;
}

.director__title {
  display: flex;
  align-items: center;
  gap: 2rem;
}

.director__portrait {
  display: inline-block;
  width: 6.1875rem;
  height: 6.1875rem;
  border-radius: 50%;
  object-fit: cover;
}

.director__link {
  margin: 0;
  font-weight: normal;
}

.keyword-title {
  margin-bottom: 1.25rem;
}

.keyword-list {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;

  .keyword-container & {
    max-width: var(--w-prose);
    margin-inline: auto;
    margin-bottom: var(--spacing);
  }
}
