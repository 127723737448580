html {
  scroll-behavior: smooth;
}

.hero {
  position: relative;
  z-index: var(--z-hero);
  margin-top: var(--hero-gap);

  padding-left: var(--hero-gap);
  padding-right: var(--hero-gap);

  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: minmax(0, auto);
  gap: 0 var(--hero-gap);

  max-width: var(--hero-max-width);
  margin-inline: auto;

  &.hero--left,
  &.hero--right {
    grid-template-areas:
      "large"
      "large"
      "small0"
      "small1";
  }

  @media (--media-lg) {
    &.hero--left,
    &.hero--right {
      grid-template-columns: 1fr 1fr;
    }

    &.hero--left {
      grid-template-areas:
        "large small0"
        "large small1";
    }

    &.hero--right {
      grid-template-areas:
        "small0 large"
        "small1 large";
    }
  }
}

.hero-container {
  &.hero-container--large {
    grid-column-start: large;
    grid-column-end: large;
    grid-row-start: large;
    grid-row-end: large;
  }

  &.hero-container--small--0 {
    grid-column-start: small0;
    grid-column-end: small0;
    grid-row-start: small0;
    grid-row-end: small0;
  }

  &.hero-container--small--1 {
    grid-column-start: small1;
    grid-column-end: small1;
    grid-row-start: small1;
    grid-row-end: small1;
  }
}

.hero__box {
  display: block;
  position: relative;

  img {
    border-radius: var(--hero-border-radius);
    width: 100%;
    /* this squashes the image by a small amount, sorry */
    height: calc(100% - var(--hero-gap));
  }

  height: 100%;
}

.hero__infobox {
  position: absolute;
  bottom: var(--hero-gap);
  margin: 1.5rem;
  padding: 1.5rem;
  background-color: var(--hero-details-bg);
  border-radius: var(--hero-border-radius);
  width: calc(100% - (2 * 1.5rem));
  box-shadow: 0px 0px 1.5rem 0px rgba(0, 0, 0, 0.15);
  backdrop-filter: blur(5px);

  .magazine & p {
    font-family: GT-Walsheim, Helvetica, Arial, sans-serif;
  }
}
