.team-elements {
  gap: 2.5rem var(--grid-gap);
}

.team-element {
  font-size: 1.125rem;
  line-height: 1.5rem;

  figure {
    margin-bottom: 1rem;
  }
}

.team-element__name {
  font-weight: 700;
}
