.htmx-indicator {
  opacity: 0;
  transition: opacity 3s ease-in;
}
.htmx-request .htmx-indicator {
  opacity: 1;
}
.htmx-request.htmx-indicator {
  opacity: 1;
}

.loading {
  display: flex;
  gap: 0.5rem;
  .loading__circle {
    border-radius: 50%;
    border: 2px solid var(--dark);
    width: 1rem;
    height: 1rem;
    background-color: var(--red);
    animation: loading-bounce 1.66s ease-in-out infinite;

    &:nth-of-type(2) {
      background-color: var(--lime);
      animation-name: loading-bounce2;
    }
    &:nth-of-type(3) {
      background-color: var(--yellow);
      animation-name: loading-bounce3;
    }
  }
}

@keyframes loading-bounce {
  0% {
    transform: scale(0.5);
  }
  25% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.5);
  }
  75% {
    transform: scale(0.5);
  }
  100% {
    transform: scale(0.5);
  }
}
@keyframes loading-bounce2 {
  0% {
    transform: scale(0.5);
  }
  25% {
    transform: scale(0.5);
  }
  50% {
    transform: scale(1);
  }
  75% {
    transform: scale(0.5);
  }
  100% {
    transform: scale(0.5);
  }
}
@keyframes loading-bounce3 {
  0% {
    transform: scale(0.5);
  }
  25% {
    transform: scale(0.5);
  }
  50% {
    transform: scale(0.5);
  }
  75% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.5);
  }
}
