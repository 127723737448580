:root {
  --c: 5.5rem;
  --c2: 12.5rem;
  --c3: 19.5rem;
  --c4: 26.5rem;
  --c5: 33.5rem;
  --c6: 40.5rem;
  --c7: 47.5rem;
  --c8: 54.5rem;
  --c9: 61.5rem;
  --c10: 68.5rem;
  --c11: 75.5rem;
  --c12: 82.5rem;
  --c-outer: 85.51rem;

  --w: var(--c-outer);
  --w-content: var(--c10);
  --w-prose: var(--c8);
  --w-max: 94.5rem;

  --p: 1.5rem;
  --gap: 1.5rem;

  --spacing-s: 0.5rem;
  --spacing: 2.5rem;

  --margin: 3rem;
  --margin-m: 6.5rem;
  --margin-l: 10rem;
  --margin-xl: 12.5rem;

  @media (--media-sm-only) {
    --p: 1rem;
    --gap: 1rem;

    --spacing-s: 0.25rem;
    --spacing: 1.25rem;

    --margin: 1.5rem;
    --margin-m: 3.25rem;
    --margin-l: 5rem;
    --margin-xl: 6.25rem;

    --w-content: 100%;
    /* --w-prose: 80%; */
  }

  --icon-size: 1.5em;

  --header-height: 5.25rem;
  --header-height--sm: 3em;

  --hero-max-width: 120rem;
  --hero-gap: 0.5rem;
  --hero-border-radius: 1rem;

  --filters-margin: 6rem 0 4rem 0;
  --filter-size: 2.25rem;
  --filter-font-size: 1rem;

  --featured-margin-bottom: 5rem;

  --magazine-box-gap: 0.75rem;

  --interact-size: max(12px, 1.33rem);
  --screening-animation-time: 0.5s;

  --z-gallery-gradient: 10;
  --z-gallery-control: 12;
  --z-newsletter-widget: 16;
  --z-hero: 17;
  --z-main-gradient: 18;
  --z-footer: 19;
  --z-header: 20;
  --z-modal: 50;
  --z-mobile-menu: 90;
  --z-menu-cta: 93;
}
