:root {
  --lime: #ccfb6f;
  --dark: #313646;
  --gray: #ebf4ee;
  --black: #0a1129;
  --white: #fefefe;
  --yellow: #fdff9d;
  --red: #ff957b;
  --green: #72eeba;
  --pink: #fa94b5;

  --lime-100: #fafff1;
  --lime-200: #ebfdc5;
  --lime-300: #d6fc8c;
  --lime-400: var(--lime);
  --lime-500: #b1de59;
  --lime-600: #8cb043;
  --lime-700: #618519;
  --lime-800: #466803;
  --lime-900: #3b530d;
  --lime-1000: #2b4002;

  --black-100: var(--white);
  --black-200: #eaebec;
  --black-300: #d6d7da;
  --black-400: #adafb5;
  --black-500: #838690;
  --black-600: #5a5e6b;
  --black-700: var(--dark);
  --black-800: var(--black);

  --white-alpha40: rgba(255, 255, 255, 0.4);
  --dark-alpha40: rgba(49, 54, 70, 0.4);

  --text-fg: var(--dark);
  --main-bg: var(--gray);

  --button-fg: var(--gray);
  --button-bg: var(--dark);
  --button-hover-fg: var(--dark);
  --button-hover-bg: var(--lime);

  --link-fg: var(--black);
  --link-decoration: var(--lime);
  --icon-fg: var(--gray);

  --errors-fg: var(--red);

  --header-bg: var(--dark);
  --header-home-bg: var(--gray);
  --mobile-menu-bg: var(--dark);

  --menu-button-fg: var(--gray);
  --menu-button-bg: transparent;
  --menu-button-hover-fg: var(--gray);
  --menu-button-hover-bg: var(--black);
  --menu-button-active-fg: var(--lime);
  --menu-button-active-bg: transparent;

  --menu-button-home-fg: var(--dark);
  --menu-button-home-bg: transparent;
  --menu-button-home-hover-fg: var(--gray);
  --menu-button-home-hover-bg: var(--black);
  --menu-button-home-active-fg: var(--lime);
  --menu-button-home-active-bg: transparent;

  --submenu-button-fg: var(--gray);
  --submenu-button-bg: transparent;
  --submenu-button-hover-fg: var(--lime);
  --submenu-button-hover-bg: transparent;
  --submenu-button-active-fg: var(--gray);
  --submenu-button-active-bg: transparent;

  --hero-font-color: var(--white);
  --hero-details-bg: var(--white-alpha40);

  --footer-bg: var(--black);
  --footer-fg: var(--white);

  --magazine-fg: var(--gray);
  --magazine-bg: var(--dark);

  --article-teaser-bg: var(--dark);

  --tag-fg: var(--gray);
  --tag-bg: var(--dark);
  --tag-hover-fg: var(--dark);
  --tag-hover-bg: var(--lime);
}

body.home header#header {
  --header-bg: var(--header-home-bg);

  --menu-button-fg: var(--menu-button-home-fg);
  --menu-button-bg: var(--menu-button-home-bg);
  --menu-button-hover-fg: var(--menu-button-home-hover-fg);
  --menu-button-hover-bg: var(--menu-button-home-hover-bg);
  --menu-button-active-fg: var(--menu-button-home-active-fg);
  --menu-button-active-bg: var(--menu-button-home-active-bg);
}

.category--lime,
.magazine .category--lime {
  color: var(--lime);
  background-color: transparent;
  border-color: var(--lime);

  /* &:hover, */
  /* &--filled { */
  /*   color: var(--dark); */
  /*   background-color: var(--lime); */
  /* } */
}

.category--yellow,
.magazine .category--yellow {
  color: var(--yellow);
  background-color: transparent;
  border-color: var(--yellow);

  /* &:hover, */
  /* &--filled { */
  /*   color: var(--dark); */
  /*   background-color: var(--yellow); */
  /* } */
}

.category--red,
.magazine .category--red {
  color: var(--red);
  background-color: transparent;
  border-color: var(--red);

  /* &:hover, */
  /* &--filled { */
  /*   color: var(--dark); */
  /*   background-color: var(--red); */
  /* } */
}

.category--green,
.magazine .category--green {
  color: var(--green);
  background-color: transparent;
  border-color: var(--green);

  /* &:hover, */
  /* &--filled { */
  /*   color: var(--dark); */
  /*   background-color: var(--green); */
  /* } */
}

.category--pink,
.magazine .category--pink {
  color: var(--pink);
  background-color: transparent;
  border-color: var(--pink);

  /* &:hover, */
  /* &--filled { */
  /*   color: var(--dark); */
  /*   background-color: var(--pink); */
  /* } */
}

.lime {
  --feature-bg: var(--lime);
  --feature-fg: var(--dark);
}
.dark {
  --feature-bg: var(--dark);
  --feature-fg: var(--white);
}
.dark-yellow {
  --feature-bg: var(--dark);
  --feature-fg: var(--yellow);
}
.gray {
  --feature-bg: var(--gray);
  --feature-fg: var(--dark);
}
.black {
  --feature-bg: var(--black);
  --feature-fg: var(--white);
}
.white {
  --feature-bg: var(--white);
  --feature-fg: var(--dark);
}
.yellow {
  --feature-bg: var(--yellow);
  --feature-fg: var(--dark);
}
.red {
  --feature-bg: var(--red);
  --feature-fg: var(--dark);
}
.green {
  --feature-bg: var(--green);
  --feature-fg: var(--dark);
}
.pink {
  --feature-bg: var(--pink);
  --feature-fg: var(--dark);
}

.black-300 {
  --feature-fg: var(--dark);
  --feature-bg: var(--black-300);
}

.text--lime {
  color: var(--lime);
}
