footer {
  padding: var(--margin) 0;
  background: var(--footer-bg);
  color: var(--footer-fg);

  --icon-fg: var(--footer-fg);

  .contact-container {
    display: flex;
    flex-direction: column;
    gap: var(--spacing);
  }

  .logo-container {
    max-width: 12rem;
    height: auto;
    flex: 0 1 auto;
  }

  .contact-link {
    --icon-size: 1.5rem;
    display: flex;
    align-items: center;
    gap: 0.5em;

    &:hover {
      color: var(--lime);
    }
  }

  .some-links-container {
    --icon-size: 2.75rem;
    font-size: var(--interact-size);

    display: flex;
    gap: 1rem;
    margin-top: auto;
  }

  .some-link {
    &:hover,
    &:active,
    &:focus {
      .icon {
        fill: var(--lime);
      }
    }
  }

  .nav-container {
    display: flex;
    flex-flow: column;
  }

  .language-nav {
    margin-top: auto;

    .nav-list {
      flex-flow: row;
      justify-content: flex-start;
    }
  }

  .nav-list {
    flex-flow: column;
    align-items: flex-start;
    font-weight: 700;
  }

  .nav-list-item {
    &:hover,
    &:focus-visible,
    &.active {
      text-decoration: 2px solid currentColor;
    }
  }

  .nav-link {
    padding: 0.5em 0;

    &:hover {
      color: var(--lime);
    }
  }

  .icon {
    display: block;
  }
}

.footer-container {
  display: flex;
  flex-flow: row wrap;
  gap: var(--margin);
  justify-content: space-between;
}

.footer-info {
  font-size: 1.25rem;

  @media (--media-md) {
    max-width: calc(33.33% - var(--margin));
  }
}

.footer-info__zewo-seal {
  display: block;
  margin-top: var(--spacing);
}
