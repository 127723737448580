.map {
  --film-count-color-0: var(--black-300);
  --film-count-color-1: #ebfdc5;
  --film-count-color-2: #d6fc8c;
  --film-count-color-3: #ccfb6f;
  --film-count-color-4: #b1de59;
  --film-count-color-5: #8cb043;
  --film-count-color-6: #618519;
  --film-count-color-7: #466803;
  --film-count-color-8: #3b530d;
  --film-count-color-9: #2b4002;
}

.map__wrap {
  position: relative;
}

.map__country[role="button"] {
  cursor: pointer;
}

.map-tooltip {
  padding: 0.25rem;
  background-color: var(--white);
  border: 1px solid var(--black);
  position: absolute;
  overflow: hidden;
  transform: translate(-50%, calc(-100% - 1em)) scale(0);

  transition: transform 0.3s;
  &[aria-hidden="true"] {
    transform: translate(-50%, calc(-100% - 1em)) scale(0);
  }

  &[aria-hidden="false"] {
    transform: translate(-50%, calc(-100% - 1em)) scale(1);
  }
}
