main {
  flex: 1 1 100%;
  background-color: var(--main-bg);
  position: relative;
}

main {
  padding-top: calc(
    var(--margin-m) + var(--header-height) + (2 * var(--header-padding))
  );
}

/* different behaviour on magazine */
body.home,
body.magazine {
  main {
    padding-top: calc(var(--header-height) + (2 * var(--header-padding)));
    @media (--media-sm-only) {
      padding-top: calc(var(--header-height--sm) + (2 * var(--header-padding)));
    }
  }
}

main {
  > .container:last-child {
    margin-bottom: var(--margin-m);
  }
}

.container {
  max-width: var(--w);
  width: 100%;

  padding: 0 var(--p);

  margin-left: auto;
  margin-right: auto;
}

.container > .container {
  --p: 0;
}

.container {
  &.maxed {
    padding: 0;
    max-width: var(--w-max);
  }
  &.sm--full {
    padding: 0;
    @media (--media-md) {
      padding: 0 var(--p);
    }
  }
  &.md--full {
    padding: 0;
    @media (--media-lg) {
      padding: 0 var(--p);
    }
  }
}

.m {
  margin: var(--margin);
}

.mb {
  margin-bottom: var(--margin);
}
