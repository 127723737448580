details {
  --collapsed: auto;
  --expanded: auto;
  height: var(--collapsed);
  overflow: hidden;
  transition: height 0.3s ease-out;
}

details[open] {
  height: var(--expanded);
}

summary {
  list-style: none;
  cursor: pointer;

  &::-webkit-details-marker {
    display: none;
  }
}

.details {
  &[open] > summary {
    &::after {
      transform: rotate(180deg);
    }

    .form-details__button {
      display: none;
    }
  }

  summary {
    position: relative;
    &::after {
      content: "";
      height: 2rem;
      width: 2rem;
      position: absolute;
      right: 0;
      top: 0;
      display: block;
      background: url(/static/assets/icon_arrow_down.svg) center no-repeat;
      background-size: contain;
      transition: transform 0.2s ease;
    }
  }
}
