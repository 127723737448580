body:not(.is-pro) .pro-display {
  display: none;
}

.is-pro {
  /* manually set the cols */
  --pro-grid-col-0: 24fr;
  --pro-grid-col-1: 244fr;
  --pro-grid-col-2: 196fr;
  --pro-grid-col-3: 168fr;
  --pro-grid-col-4: 84fr;
  --pro-grid-col-5: 132fr;
  --pro-grid-col-6: 84fr;
  --pro-grid-col-7: 84fr;
  --pro-grid-col-8: 132fr;

  .guest-display {
    display: none;
  }

  /* .pro-display {
    display: unset;
  } */

  tr.pro-display {
    display: table-row;
  }

  .film-grid {
    gap: 0;
    grid-template-columns: 1fr;

    .card {
      grid-template-columns: 1fr;
      grid-template-areas: "info";
      background-color: transparent;
      box-shadow: none;
      border-top: 1px solid var(--dark);
      border-radius: 0px;
      padding: 1rem 0px;
    }

    .card__details-inner {
      display: grid;
      --grid-gap: 1rem;
      gap: var(--grid-gap);
      grid-template-columns:
        var(--pro-grid-col-0)
        var(--pro-grid-col-1)
        var(--pro-grid-col-2)
        var(--pro-grid-col-3)
        var(--pro-grid-col-4)
        var(--pro-grid-col-5)
        var(--pro-grid-col-6)
        var(--pro-grid-col-7)
        var(--pro-grid-col-8);
    }

    .card__info:not(.guest-display) {
      display: flex;
      align-items: center;
      color: black;
      font-size: 1rem;
      font-style: normal;
      line-height: 1.4em;
      gap: 1rem;

      img {
        height: 5rem;
      }
    }

    h4.card__info {
      margin-bottom: 0px;
      font-weight: 700;
    }

    .card__info--bold {
      font-weight: normal;
    }
  }
}

.pro-toggle {
  display: flex;
  justify-content: flex-end;
  background: linear-gradient(to left, var(--lime) 50%, transparent 50%) left;
  background-size: 200% 100%;
  transition: background-position 0.5s ease-in-out;

  .toggle {
    position: relative;
    cursor: pointer;
    width: 2.5em;
    height: 1.4em;
    background: grey;
    display: block;
    border-radius: 1em;
    outline: none;
    border: 0.1em solid var(--dark);
    background: var(--gray);

    &:after {
      content: "";
      position: absolute;
      top: 0.2em;
      left: 0.2em;
      width: 0.8em;
      height: 0.8em;
      background: var(--dark);
      border-radius: 1em;
      transition: 0.3s;
    }

    .is-pro & {
      background: var(--lime);
    }

    .is-pro &:after {
      left: calc(100% - 0.2em);
      transform: translateX(-100%);
    }
  }

  .is-pro & {
    background-position: right;
  }

  .label {
    color: var(--gray);
    font-weight: bold;
    cursor: pointer;
  }
}

.pro-toggle__button {
  display: flex;
  align-items: center;
  gap: 0.8rem;
  padding: 0.5rem 1rem 0.75rem 1.5rem;
  background-color: var(--dark);
  border-bottom-left-radius: 1.5rem;
}
