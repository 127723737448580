.film__backlink {
  position: absolute;
  top: 0px;
  left: var(--p);

  @media (--media-sm-only) {
    top: -2rem;
  }
}

h1.film__title {
  margin-bottom: 0.5rem;
  margin-top: 2rem;

  @media (--media-lg) {
    margin-top: 0;
  }
}

.picture-list {
  margin-bottom: var(--spacing);
  max-width: var(--w-prose);
  margin-inline: auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  gap: var(--gap);

  img {
    object-fit: contain;
  }
}

.film__meta {
  margin-bottom: 2.5rem;

  & .film__meta__buttons,
  & .film__meta__info {
    max-width: var(--w-prose);
    margin-inline: auto;
  }
}

.film__meta__buttons {
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  gap: 0.5rem;

  .button {
    margin-bottom: 2.5rem;
  }
}

.film__media {
  min-height: 12rem;
  @media (--media-md) {
    min-height: 22rem;
  }
  figure,
  .embedded-video {
    max-width: var(--w-prose);
    margin-inline: auto;
    margin-bottom: 0.75rem;
  }

  img {
    margin-inline: auto;
  }
}

.film__credits {
  display: flex;
  max-width: var(--w-content);
  margin: var(--margin-m) auto;
  margin-inline: auto;

  @media (--media-sm-only) {
    flex-flow: column;

    img {
      max-width: 66.666%;
    }
    .film__credits__artwork {
      max-width: 50%;
      margin-bottom: 2rem;
      img {
        max-width: 100%;
      }
    }
  }
}

.film__credits__title {
  font-size: 1.75rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.1em;
  margin-bottom: 1.5rem;

  width: 100%;
}

.film__credits__details {
  flex: 1;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.4em;
}

.film__credits__table {
  margin-bottom: 1rem;
  flex: 1;

  @media (--media-md) {
    .is-pro & {
      columns: 2;
    }
  }
}

.film__credits__credit {
  display: flex;
  gap: 2rem;
}

.film__credits__term {
  font-weight: bold;
  white-space: nowrap;
  flex: 1;
}

.film__credits__content {
  flex: 2;
}

.film__promaterial {
  max-width: var(--w-content);
  margin-inline: auto;

  .card {
    padding: 2rem;
  }
}

.film__promaterial-title {
  font-size: 1.75rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.1em;
  margin-bottom: 1.5rem;

  width: 100%;
}

.film__promaterial-content {
  margin-bottom: 1.5rem;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.4em;
}

.film__promaterial-pictures {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  margin-bottom: 1.5rem;

  a.clean {
    max-width: calc(50% - (1rem / 2));
  }

  img {
    height: 17.6875rem;
    max-width: 100%;
    object-fit: contain;
  }
}

.film__promaterial-buttons {
  margin-bottom: 2.5rem;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 1rem;

  &:last-of-type {
    margin-bottom: 0;
  }
}

.film__magazine {
  --grid-cell: var(--c4);

  margin: var(--margin-m) auto;
  background-color: var(--magazine-bg);
  padding-top: 5rem;
  padding-bottom: 5rem;

  .container--articles {
    width: var(--w-prose);
    margin-inline: auto;
  }

  .article-list {
    margin-top: 3rem;
    gap: var(--grid-gap--magazine);
    grid-template-columns: 1fr 1fr;

    @media (--media-sm-only) {
      grid-template-columns: 1fr;
    }

    margin-bottom: 0px;
  }

  h3,
  a {
    color: var(--white);
  }
}
