:root {
  --header-padding: 1rem;
}

header#header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: var(--z-header);
  transition: transform 0.5s ease-out;
  color: var(--header-fg);

  body.home & {
    box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.1);
  }

  body.scrolled & {
    transform: translateY(-100%);
  }

  body.scrolle-up & {
    transform: none;
  }
}

.header-wrapper {
  transition: background-color 0.3s ease-in-out;
  background-color: var(--header-bg);
  padding-top: var(--header-padding);
  padding-bottom: var(--header-padding);
}

.header-container,
.mobile-menu .toggle-container {
  height: var(--header-height);
  display: flex;
  align-items: center;

  @media (--media-sm-only) {
    height: var(--header-height--sm);
  }
}

.header-container {
  justify-content: space-between;
  gap: var(--spacing);
}
.toggle-container {
  justify-content: right;
}

header .logo-image {
  max-height: 100%;
  height: 3.125rem;

  @media (--media-sm-only) {
    height: 2.5rem;
  }
}

.logo-container {
  flex: 1 0 auto;
  height: 100%;
  display: flex;
  align-items: center;
}

.menu-is-open {
  overflow: hidden;
}

.mobile-menu {
  display: none;

  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  min-height: 100vh;

  background: var(--mobile-menu-bg);
  z-index: var(--z-mobile-menu);

  overflow-y: auto;

  padding-bottom: var(--margin-l);
  padding-top: var(--header-padding);

  .nav-toggle {
    margin-left: auto;
  }

  .nav-link {
    color: var(--submenu-button-fg);
    background-color: var(--submenu-button-bg);
    border: none;
    letter-spacing: 0.0125em;
    padding: 0.5rem 0.75rem;

    &:hover {
      color: var(--submenu-button-hover-fg);
      background-color: var(--submenu-button-hover-bg);
      text-decoration: none;
    }
  }

  @media (--media-md) {
    left: auto;
    min-width: 18.5rem;
    padding-left: 0.25rem;
    box-shadow: -8px 0px 24px 0px rgba(0, 0, 0, 0.1);

    .nav-list {
      align-items: flex-start;
    }
  }
}

.cta-container {
  flex: 0 1 auto;
  margin-left: auto;
  .button {
    border-color: var(--black);
  }
}

.menu-is-open .mobile-menu {
  display: block;
}

.main-nav {
  flex: 1 1 auto;
  .nav-list {
    justify-content: right;
  }
  .nav-list-item {
    &.active .nav-link {
      color: var(--menu-button-active-fg);
      background-color: var(--menu-button-active-bg);
      text-decoration: none;
    }
  }
  .nav-link {
    color: var(--menu-button-fg);
    background-color: var(--menu-button-bg);
    border: none;
    letter-spacing: 0.0125em;
    padding: 0.5rem 0.75rem;

    &:hover {
      color: var(--menu-button-hover-fg);
      background-color: var(--menu-button-hover-bg);
      text-decoration: none;
    }
  }

  @media (--media-sm-only) {
    display: none;
  }
}

.nav-list {
  display: flex;
  gap: 1rem;
  align-items: center;
  flex-wrap: wrap;

  @media screen and (max-height: 800px) {
    gap: 1em;
  }
}

.language-nav {
  .nav-list {
    margin-top: var(--margin);

    @media (--media-sm-only) {
      justify-content: center;
    }
  }
  .nav-link {
    font-weight: normal;
  }

  .active .nav-link {
    font-weight: bold;
    color: var(--menu-button-active-fg);
  }
}

.mobile-nav {
  font-size: 1.33rem;

  .nav-list {
    flex-direction: column;
  }
}

.sub-nav {
  .nav-list {
    margin-top: var(--margin);
    flex-direction: column;
  }
  .nav-link {
    font-weight: normal;
  }
}
