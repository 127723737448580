.form {
  --input-border: 1px solid var(--black);
  font-size: var(--interact-size);

  fieldset {
    border: 0;
    padding: 0;
    margin: 0;
    transition: opacity 0.1s ease-out;
  }

  fieldset[disabled] {
    opacity: 0.7;
  }

  .field {
    margin-bottom: var(--grid-gap);
  }

  small {
    display: block;
  }

  .field > label {
    display: block;
    margin-bottom: calc(0.25 * var(--grid-gap));
  }

  .field > label.required::after {
    content: "*";
  }

  .field > label.error {
    color: var(--errors-fg);
  }

  input[type="text"],
  input[type="email"],
  input[type="password"],
  input[type="number"],
  select,
  textarea {
    border: var(--input-border);
    padding: 0.75rem 0.5rem;
    border-radius: 0;
    &:focus {
      box-shadow: 0px 0px 12px 0px rgba(10, 17, 41, 0.3);
      outline: none;
    }
  }

  .widget--radioselect {
    display: flex;
    flex-direction: column;
    gap: 0.2rem;
  }

  .widget--radioselect label {
    margin-left: 0.5rem;
  }

  .widget--selectdatewidget {
    display: flex;
    flex-flow: row wrap;
    label {
      flex: 1 0 100%;
    }
    select {
      flex: 0 1 min-content;
      &:not(:last-of-type) {
        margin-right: var(--spacing-s);
      }
    }
  }

  .widget--hiddeninput {
    display: none;
  }

  .help {
    font-size: 0.8em;
    margin-top: calc(0.25 * var(--grid-gap));
  }

  .errors,
  .errorlist {
    color: var(--errors-fg);
  }

  .message {
    font-size: 1.2rem;
    font-weight: bold;
    color: var(--gray);
  }
}

@supports (-webkit-appearance: none) or (-moz-appearance: none) {
  .form {
    input[type="number"] {
      -moz-appearance: textfield;
    }
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }

    label {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 0.5rem;
    }

    input[type="checkbox"],
    input[type="radio"] {
      border: none;
      -webkit-appearance: none;
      -moz-appearance: none;
      flex: 0 0 auto;

      font-size: 1.25rem;

      height: 1.5rem;
      width: 1.5rem;

      /* outline: none; */
      display: inline-block;
      vertical-align: middle;
      position: relative;
      margin: 0;
      cursor: pointer;

      &:focus {
        /* box-shadow: 3px 2px 11px 0 var(--black);
        outline: none; */
      }

      & + label {
        cursor: pointer;
      }
    }

    input[type="checkbox"] {
      background: url(/static/assets/icon_checkbox.svg) center no-repeat;
      background-size: contain;

      &:checked {
        background-image: url(/static/assets/icon_checkbox_checked.svg);
      }
    }

    input[type="radio"] {
      background: url(/static/assets/icon_radio.svg) center no-repeat;
      background-size: contain;

      &:checked {
        background-image: url(/static/assets/icon_radio_checked.svg);
      }
    }
  }
}

.form-details {
  max-width: var(--w-content);
  margin: var(--margin-m) auto;
  margin-inline: auto;

  --text-fg: var(--dark);

  details {
    &[open] > summary {
      &::after {
        transform: rotate(180deg);
      }

      .form-details__button {
        display: none;
      }
    }
  }

  summary {
    padding-right: 5.5rem;
    position: relative;
    &::after {
      content: "";
      height: 3rem;
      width: 3rem;
      position: absolute;
      right: 0;
      top: 0;
      display: block;
      background: url(/static/assets/icon_arrow_down.svg) center no-repeat;
      background-size: contain;
      transition: transform 0.2s ease;
    }
  }
}

form.film--form,
form.membership--form {
  --input-border: 1px solid var(--dark);
  margin-inline: auto;
  margin-top: 2.5rem;

  label {
    font-size: 1.25rem;
    font-style: normal;
    font-weight: normal;
    line-height: 1.4em;
    margin-bottom: 0.5rem;
  }

  label + input[type="checkbox"],
  label + input[type="radio"],
  label + div {
    margin-bottom: 1rem;
  }

  input,
  input[type="text"],
  input[type="date"],
  input[type="email"],
  input[type="number"],
  textarea,
  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    border-radius: 0.25rem;
    margin-bottom: 1rem;
    border: var(--input-border);
    max-width: 100%;
    padding: 0.25rem 0.875rem;
  }

  input[type="text"],
  input[type="email"],
  textarea,
  select {
    width: 100% !important;
  }

  input[type="date"],
  input[type="number"] {
    width: var(--c2);
  }

  legend {
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.4em;
    margin-bottom: 0.75rem;
    margin-top: 1.5rem;
    display: block;
    padding: 0;
  }

  button {
    margin-top: 1.5rem;
  }
}

.form-details--film {
  scroll-margin: 12.5rem;

  &.card {
    padding: 2rem;
  }

  .form {
    max-width: var(--c6);
  }

  .form-details__title {
    margin-bottom: 0.75rem;
  }

  .form-details__description {
    margin-bottom: 0.75rem;
  }
}

.form-details--membership {
  .card {
    background-color: var(--red);
    border-radius: 0px;
  }

  .form {
    margin-top: 4.19rem;
    max-width: 100%;
  }

  .field {
    margin-bottom: unset;
  }

  .checkbox-field {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    input[type="checkbox"] {
      margin-bottom: calc(0.25 * var(--grid-gap));
    }
  }
}

.form-message {
  scroll-margin: var(--margin-l);
}
