.newsletter-widget {
  pointer-events: none;
  position: sticky;
  bottom: 0;
  transform: translateY(-1rem);
  right: var(--p);
  z-index: var(--z-newsletter-widget);
  transition: transform 0.5s ease-out;
  margin-left: var(--p);
  display: flex;

  .icon--mail {
    margin-left: 1rem;
  }
}

.newsletter-form {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  flex-flow: wrap;

  /* oh no */
  input[type="email"] {
    height: 2.5rem;
    border: none;
    border-radius: 0.25rem;
  }

  .field {
    margin-bottom: 0;
  }
}

[aria-expanded="false"] {
  & + .newsletter-form {
    display: none;
  }

  .icon--arrow {
    transform: rotate(180deg);
  }
}

.button--newsletter-widget {
  --icon-size: 1.5rem;
  --icon-fg: var(--lime);
  height: 2.5rem;
  border-radius: 2rem;
  padding: 0.5rem;
  background-color: var(--feature-bg);
  border-color: var(--feature-bg);
  flex-shrink: 0;

  @media (hover: hover) {
    &:hover {
      --icon-fg: var(--lime);
      background-color: var(--feature-bg);
      border-color: var(--feature-bg);
    }
  }
}

.container--newsletter-widget {
  pointer-events: all;
  color: var(--feature-fg);
  background-color: var(--feature-bg);
  display: flex;
  gap: 1rem;
  align-items: center;
  border-radius: 2rem;
  padding: 0.5rem;
  width: auto;
  margin-right: var(--p);
}

.newsletter-form__claim {
  display: none;
  flex: 1;

  @media (--media-sm-only) {
    flex-basis: 100%;
  }
}

.widget-expanded .newsletter-form__claim {
  display: block;
}

.newsletter-widget .newsletter-form {
  display: none;
  flex: 1;
}

.newsletter-widget.widget-expanded .newsletter-form {
  display: flex;
}

.widget-expanded .newsletter-form--widget {
  padding: calc(0.5 * var(--p));
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: var(--gap);

  @media (--media-md) {
    flex-wrap: nowrap;
  }
}

.newsletter-form__claim {
  text-align: center;
}
