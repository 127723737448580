.newsletter-teaser {
  padding: var(--spacing);

  .button--newsletter-widget {
    pointer-events: none;
    padding-left: 0;
  }

  .icon--arrow {
    display: none;
  }
}

.newsletter-teaser__form-wrap {
  display: flex;
  gap: var(--gap);
}

.container--newsletter-teaser {
  width: var(--w-content);
  max-width: 100%;
}

.newsletter-teaser__title {
  color: var(--lime);
  text-align: left;
  margin-bottom: var(--p);
}

.newsletter-teaser__text {
  margin-bottom: var(--p);
}

.newsletter-teaser__icon {
  --icon-size: 1.5rem;
  --icon-fg: var(--lime);
  height: 2.5rem;
  padding: 0.5rem;
  background-color: var(--feature-bg);
  border-color: var(--feature-bg);
}
