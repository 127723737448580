#results {
  scroll-margin: 12.5rem;
}

.pagination {
  /* margin-block: var(--margin); */
  width: 100%;
  display: flex;
  justify-content: center;
  grid-column: 1/ -1;
  /* margin-top: var(--grid-gap);
  margin-top: calc(2 * var(--grid-gap)); */
}

.pagination--load-more {
  line-height: 1;
  padding: 0.75rem 1rem;
  border: none;
}

.pagination__control {
  &.inactive {
    opacity: 0.2;
    cursor: unset;
  }
}

.pagination__control--left {
  margin-right: var(--margin);
}

.pagination__control--item {
  margin-inline: var(--spacing);
  &.current {
    font-weight: bold;
    color: var(--red);
  }
}

.pagination__control--right {
  margin-left: var(--margin);
}
