.cards {
  gap: calc(2 * var(--grid-gap));
}

.card {
  padding: var(--grid-gap);
  border-radius: 0.5rem;
  gap: var(--grid-gap);

  box-shadow: 0px 0px 1.5rem 0px rgba(0, 0, 0, 0.1);
  background-color: var(--white);

  opacity: 0;
  animation: fade-in 0.5s ease-in forwards;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.card__image {
  grid-area: image;
}

.card__image img {
  max-width: auto;
  max-height: 100%;
  width: 100%;
}

.card__details {
  grid-area: info;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: 100%;
}

h4.card__info {
  text-align: unset;
  margin: 0;
  margin-bottom: 1rem;
}

.card__info--bold {
  font-weight: bold;
}

.card__link {
  margin-top: 1.5rem;
}

.card__director {
  display: flex;
  flex-direction: row;
  gap: var(--gap);
  text-align: left;
}

.magazine--detail {
  .info__director,
  .info__country,
  .info__year {
    display: none;
  }
}

.info__description {
  @media (--media-sm-only) {
    display: none;
  }
}
