.card.film-box--edu {
  display: flex;
  gap: var(--grid-gap);
  max-width: var(--w-content);
  margin-inline: auto;

  @media (--media-sm-only) {
    flex-direction: column;
  }

  .card__details {
    /* flex-basis: 3fr; */
    display: flex;
    flex-direction: column;

    dl {
      margin-bottom: 0.5rem;
    }
    dt {
      font-weight: bold;
    }
  }

  .card__link {
    padding-top: 1rem;
    margin-top: auto;
  }

  .box-content {
    flex: 2 1 var(--c6);
    max-width: 100%;

    &--film {
      flex: 1 1 var(--c3);
      display: flex;
      flex-direction: column;
      gap: var(--grid-gap);

      @media (--media-sm-only) {
        flex-direction: row;
        margin-bottom: 1.5em;
      }

      dt {
        display: none;
      }
    }
  }
}

ul.topics-list {
  display: flex;
  flex-flow: wrap;
  gap: 1rem;
  font-size: 1rem;
  margin-bottom: 3em;
}

.topics-list__item {
  border: var(--dark) 1px solid;
  padding: 0.5rem 1rem;
  border-radius: 2em;
}

.box-content.box-content--edu {
  h4 {
    font-size: 1.5rem;
    margin-bottom: 1em;
  }

  p {
    margin-bottom: 3em;
  }
}

.card__image--edu {
  @media (--media-sm-only) {
    max-width: 50%;
  }
}

ul.levels-list {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 3em;
}

ul.downloads-list {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 3em;
  .download {
  }
}
