.sr-only {
  width: 0px;
  height: 0px;
  position: absolute;
  overflow: hidden;
  z-index: -1;
  &.skip-link:focus-visible {
    height: auto;
    width: auto;
  }
}

.hidden,
.hide {
  display: none !important;
}

.relative {
  position: relative;
}
