.fv-anchor {
  scroll-margin: var(--margin-l);
}
.fv-anchor-menu__container {
  position: absolute;
  top: 0;
  bottom: 0;
}
.fv-anchor-menu {
  position: sticky;
  top: 12rem;
  margin-bottom: 4rem;
  margin-left: var(--margin-m);
  display: none;
  flex-direction: column;
  gap: 2.88rem;

  .fv-anchor-menu__link {
    --size: 3.6875rem;
    display: block;
    border-radius: 50%;
    width: var(--size);
    height: var(--size);
    background-color: var(--feature-bg);
  }

  @media (--media-lg) {
    display: flex;
  }
}
