.button {
  -webkit-appearance: none; /* iOS fix */
  appearance: none;

  display: inline-flex;
  align-items: center;
  border-radius: 0.25rem;
  padding: 0 1rem;
  height: 2.75rem;
  cursor: pointer;
  line-height: 1.2em;

  font-size: var(--interact-size);

  text-decoration: none;
  font-weight: bold;

  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;

  color: var(--button-fg);

  background: var(--button-bg);
  border: 0.15em solid var(--button-bg);
  --icon-fg: var(--button-fg);

  @media (hover: hover) {
    &:hover {
      color: var(--button-bg);
      background: var(--lime);
      border-color: var(--lime);
      --icon-fg: var(--button-bg);
    }
  }
  transition:
    background-color 300ms ease,
    border 300ms ease,
    color 300ms ease;
}

.button--clean {
  background: none;
  border: none;
  color: var(--button-fg);
  --icon-fg: var(--button-fg);
  @media (hover: hover) {
    &:hover {
      background: none;
      border: none;
      color: var(--lime);
      --icon-fg: var(--lime);
    }
  }
}

.button--menu-toggle {
  --button-bg: var(--menu-button-bg);
  --button-fg: var(--menu-button-fg);
  --icon-size: 3rem;
  line-height: normal;

  letter-spacing: 0.375rem;

  .mobile-menu & {
    --button-bg: var(--submenu-button-bg);
    --button-fg: var(--submenu-button-fg);
  }
  @media (hover: hover) {
    &:hover {
      color: var(--menu-button-hover-fg);
      background: var(--menu-button-hover-bg);
    }
  }
  &[data-menu-toggle-close] {
    background: transparent;
  }
}

.button--arrow,
.button--download {
  gap: 0.625rem;
}

.button--arrow,
.button--download {
  .icon {
    transform: translateX(0.25em);
  }
}

.button--arrow-back {
  --icon-size: 1.5rem;
  --icon-fg: var(--dark);
  --button-fg: var(--dark);

  font-size: 1.25rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.2em;
  /* margin-top: 2rem; */

  gap: 0.62rem;

  .magazine & {
    --icon-size: 1.5rem;
    --icon-fg: var(--gray);
  }
}

.button-wrap {
  width: 100%;
  text-align: center;
  margin-block: var(--margin);
}

.plugin--wrap .button-wrap {
  margin-bottom: 0;
}

.button--lime {
  color: var(--dark);
  border-color: var(--lime);
  background-color: var(--lime);

  @media (hover: hover) {
    &:hover {
      color: var(--lime);
      background-color: var(--black);
      border-color: var(--black);
    }
  }
}

.button--close {
  --button-fg: var(--dark);
  --icon-size: 3rem;
  line-height: normal;
  letter-spacing: 0.375rem;
  padding: 0px;
}

.button--keyword {
  line-height: 1.5rem;
  border-radius: 1.5rem;
  font-weight: bold;
  font-size: 1rem;
}

.button--event,
.button--small {
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.5em;
}

.button--link {
  --button-fg: var(--dark);
  background: none;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.4em;
  text-decoration-line: underline;
  border: none;
  padding-left: 0px;
  padding-right: 0px;

  @media (hover: hover) {
    &:hover {
      background: none;
    }
  }
}

.button--icon {
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.5em;
  display: inline-flex;
  gap: 0.75rem;
  --button-fg: var(--gray);
  --icon-fg: var(--button-fg);
}

.button--checkmark {
  display: flex;
  gap: 0.75rem;
  padding: 0.25rem 0.875rem;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.4rem;
  border-radius: var(--category-border-radius);
  border: 1px solid var(--category-border-color);
  --button-fg: var(--category-fg);
  --button-bg: var(--category-bg);
  --icon-fg: var(--button-fg);
  height: 2rem;

  @media (hover: hover) {
    &:hover {
      color: var(--category-bg);
      background: var(--lime);
      border-color: var(--category-bg);
      --icon-fg: var(--category-bg);

      .field--classic & {
        background: var(--green);
        border-color: var(--category-fg);
        --icon-fg: var(--category-fg);
        color: var(--category-fg);
      }
    }
  }

  svg {
    display: none;
  }
}

.button--fake {
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1em;
  padding: 0.75rem 1rem;
  pointer-events: none;
}

.button--nobutton {
}

.button > span {
  text-overflow: ellipsis;
  overflow: hidden;
}

.button[disabled] {
  opacity: 0.7;
  pointer-events: none;
}
