.download-plugin {
  display: flex;
  flex-flow: wrap;
  justify-content: flex-start;
  max-width: var(--w-prose);
  margin-inline: auto;

  & .button {
    margin-inline: unset;
  }
}
