.event-list__title {
  font-size: 1.75rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.1em;
  margin-bottom: 1.5rem;
}

.event-list {
  max-width: var(--w-content);
  margin-inline: auto;
}

.event-list > .card {
  background-color: var(--white);
}

.event {
  font-size: 1.25rem;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: var(--grid-gap);

  padding-bottom: 1rem;

  ul {
    margin: 0px;
    padding: 0px;
    list-style: none;
  }

  &:not(:last-of-type) {
    border-bottom: 1px solid var(--dark);
    margin-bottom: 1rem;
  }
}

.event__date {
  font-weight: bold;
  flex: 1.2;

  @media (--media-sm-only) {
    flex-basis: 100%;
    flex: 2;
  }
}
.event__time {
  flex: 1;
}

.event__info {
  flex: 6;
}

.event__title {
  font-weight: bold;
}

.event__more {
  margin-left: auto;
}

.event__cinema {
  font-weight: bold;
  flex: 2;
}

.event__tags {
  font-weight: bold;
}

.event-list__city {
  font-size: 1.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.1em;
  padding-bottom: 1rem;
  margin-bottom: 1rem;

  border-bottom: 1px solid var(--dark);
}

.event__meta {
  flex: 4;
}

.event-list__details {
  margin-bottom: 1rem;
  .event:last-of-type {
    margin-bottom: 1rem;
    border-bottom: 1px solid var(--dark);
  }
}
